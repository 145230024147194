import { FETCH_ADMIN, FETCH_ADMIN_SUCCESS, FETCH_ADMIN_FAILED, ADMIN_SIGN_IN, ADMIN_SIGN_IN_FAILED, ADMIN_SIGN_OUT, CLEAR_LOGIN_ERROR } from '../types'

export const INITIAL_STATE = {
    info: null,
    user: null,
    loading: false,
    error: {
        flag: false,
        msg: null,
    },
}

const auth = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_ADMIN:
            return {
                ...state,
                loading: true,
            }
        case FETCH_ADMIN_SUCCESS:
            return {
                ...state,
                info: action.payload.info,
                user: action.payload.user,
                loading: false,
            }
        case FETCH_ADMIN_FAILED:
            return {
                ...state,
                loading: false,
                info: null,
            }
        case ADMIN_SIGN_IN:
            return {
                ...state,
                loading: true,
            }
        case ADMIN_SIGN_IN_FAILED:
            return {
                ...state,
                info: null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload,
                },
            }
        case ADMIN_SIGN_OUT:
            return INITIAL_STATE
        case CLEAR_LOGIN_ERROR:
            return {
                ...state,
                error: {
                    flag: false,
                    msg: null,
                },
            }
        default:
            return state
    }
}

export default auth
