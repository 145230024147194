import { Routes, Route } from 'react-router-dom'

import Layout from './components/Layout'

import Home from './views/Home'
import About from './views/About'
import Contact from './views/Contact'

const App = () => {
    return (
        <Routes>
            <Route path='/' element={<Layout />}>
                <Route index element={<Home />} />
                <Route path='/about' element={<About />} />
                {/* <Route path='/portfolio' element={<Portfolio />} /> */}
                <Route path='/contact' element={<Contact />} />
                {/* <Route path='/blog' element={<Blog />} /> */}
            </Route>
        </Routes>
    )
}

export default App
