import styled from 'styled-components'

const SlideButton = styled.button`
    &::before {
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        transition: all 0.3s ease-out;
        z-index: -1;
        background-color: #5270ff;
    }
    &:hover::before {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
`

const HomeTitle = styled.h1`
    @media (min-width: 1024px) {
        &::before {
            background-color: #5270ff;
            border-radius: 10px;
            content: '';
            height: 4px;
            left: 0;
            position: absolute;
            top: 29px;
            width: 40px;
        }
    }
`

const InfoCardNumber = styled.h3`
    &::after {
        content: '+';
        font-size: 33px;
        font-weight: 300;
        position: absolute;
        right: -24px;
        top: 2px;
    }
`

const InfoCardContent = styled.p`
    @media (min-width: 1024px) {
        &::before {
            background: #777;
            content: '';
            height: 1px;
            left: 0;
            position: absolute;
            top: 13px;
            width: 30px;
        }
    }
`

const KnobCircle = styled.div`
    border-radius: 50%;
    font-size: 120px;
    height: 1em;
    position: relative;
    width: 1em;
    background-color: #eee;
    float: none;
    margin: 0 auto;

    .dark & {
        background-color: #252525;
    }

    ${({ isHalfFull, percetange }) =>
        isHalfFull &&
        `&.p${percetange} .slice {
            clip: rect(auto, auto, auto, auto);
        }`}

    ${({ isHalfFull, percetange }) => isHalfFull && `&.p${percetange} .fill,`} & .bar {
        border: 0.08em solid #5270ff;
        border-radius: 50%;
        clip: rect(0, 0.5em, 1em, 0);
        height: 0.84em;
        position: absolute;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        width: 0.84em;
    }

    ${({ isHalfFull, percetange }) =>
        isHalfFull &&
        `&.p${percetange} .bar:after,
        &.p${percetange} .fill {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }`}

    ${({ percetange }) =>
        `&.p${percetange} .bar {
            -webkit-transform: rotate(${percetange / 100}turn);
            transform: rotate(${percetange / 100}turn);
        }`}
        
    & *,
    & :after,
    & :before {
        box-sizing: content-box;
    }

    & > span {
        font-size: 0.2em;
        left: 0;
        line-height: 5em;
        text-align: center;
        top: 0;
        transition-duration: 0.2s;
        transition-property: all;
        transition-timing-function: ease-out;
        white-space: nowrap;
        width: 100%;
        width: 5em;
        z-index: 1;
    }

    &:after,
    & > span {
        display: block;
        position: absolute;
    }

    &:after {
        background-color: #f5f5f5;
        border-radius: 50%;
        content: ' ';
        height: 0.84em;
        left: 0.08em;
        top: 0.08em;
        transition-duration: 0.2s;
        transition-property: all;
        transition-timing-function: ease-in;
        width: 0.84em;
    }

    & .slice {
        clip: rect(0, 1em, 1em, 0.5em);
        height: 1em;
        position: absolute;
        width: 1em;
    }

    &:after {
        background-color: #fff;
    }

    .dark &:after {
        background-color: #111;
    }

    @media (max-width: 576px) {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }
`

const TimelineCardPlace = styled.span`
    &::before {
        background-color: #000;
        content: '';
        height: 2px;
        left: 7px;
        opacity: 0.8;
        position: absolute;
        top: 9px;
        width: 10px;
    }

    .dark &::before {
        background-color: #fff;
    }
`

const TimelineCardResume = styled.p`
    &::before {
        border-left: 1px solid #333;
        bottom: 0;
        content: '';
        left: 20px;
        position: absolute;
        top: 0;
    }
`

export { SlideButton, HomeTitle, InfoCardNumber, InfoCardContent, KnobCircle, TimelineCardPlace, TimelineCardResume }
