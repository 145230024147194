import SectionTitle from '../components/SectionTitle'
import InfoCard from '../components/InfoCard'
import IconButton from '../components/IconButton'
import Separator from '../components/Separator'
import Knob from '../components/Knob'
import TimelineCard from '../components/TimelineCard'
import { SlideButton } from '../components/StyledComponents'
import Picture from '../components/Picture'
import { useTranslation } from 'react-i18next'
import { calculateAge } from '../utils/utils'

const About = () => {
    const { t, i18n } = useTranslation()

    const isEnglish = i18n.resolvedLanguage === 'en'

    const openCV = () => {
        const pdfUrl = i18n.resolvedLanguage === 'en' ? '/files/cv-english.pdf' : '/files/cv-spanish.pdf'
        window.open(pdfUrl, '_blank')
    }

    return (
        <div className='lg:pb-17.5'>
            <div>
                <SectionTitle backTitle={t('resume')} firstTitlePart={t('about')} secondTitlePart={t('me')} />
            </div>
            <div className='max-w-container mx-auto'>
                <div className='flex max-lg:flex-col'>
                    <div className='lg:w-1/2 px-3.75'>
                        <div>
                            <h3 className='uppercase dark:text-white text-common-title font-semibold leading-1-1/5 pb-5.5 font-poppins max-lg:text-center'>
                                {t('personalInfos')}
                            </h3>
                            <div>
                                <Picture className='lg:hidden mb-6.25' />
                            </div>
                            <div className='mb-4'>
                                <ul className='flex flex-wrap font-open-sans dark:text-white text-common leading-1-3/5 mb-4'>
                                    <li className='w-1/2 max-lg:flex flex-col pb-5'>
                                        <span className='capitalize opacity-80'>{t('firstName')}: </span>
                                        <span className='font-semibold'>Oswaldo</span>
                                    </li>
                                    <li className='w-1/2 max-lg:flex flex-col pb-5'>
                                        <span className='capitalize opacity-80'>{t('lastName')}: </span>
                                        <span className='font-semibold'>Valadez</span>
                                    </li>
                                    <li className='w-1/2 max-lg:flex flex-col pb-5'>
                                        <span className='capitalize opacity-80'>{t('age')}: </span>
                                        <span className='font-semibold'>
                                            {calculateAge('2000-12-10')} {t('years')}
                                        </span>
                                    </li>
                                    <li className='w-1/2 max-lg:flex flex-col pb-5'>
                                        <span className='capitalize opacity-80'>{t('nationality')}: </span>
                                        <span className='font-semibold'>{t('myNationality')}</span>
                                    </li>
                                    <li className='w-1/2 max-lg:flex flex-col pb-5'>
                                        <span className='capitalize opacity-80'>freelance: </span>
                                        <span className='font-semibold'>No disponible</span>
                                    </li>
                                    <li className='w-1/2 max-lg:flex flex-col pb-5'>
                                        <span className='capitalize opacity-80'>{t('address')}: </span>
                                        <span className='font-semibold'>{t('myAddress')}</span>
                                    </li>
                                    <li className='w-1/2 max-lg:flex flex-col pb-5'>
                                        <span className='capitalize opacity-80'>email: </span>
                                        <span className='font-semibold'>odvaladez@outlook.com</span>
                                    </li>
                                    <li className='w-1/2 max-lg:flex flex-col pb-5'>
                                        <span className='capitalize opacity-80'>{t('languages')}: </span>
                                        <span className='font-semibold'>{t('myLanguages')}</span>
                                    </li>
                                    <li className='w-1/2 max-lg:flex flex-col pb-5'>
                                        <span className='capitalize opacity-80'>LinkedIn: </span>
                                        <a
                                            href='https://www.linkedin.com/in/oswaldo-valadez'
                                            target='_blank'
                                            rel='noreferrer'
                                            className='font-semibold text-primary'
                                        >
                                            oswaldo-valadez
                                        </a>
                                    </li>
                                    <li className='w-1/2 max-lg:flex flex-col pb-5'>
                                        <span className='capitalize opacity-80'>GitHub: </span>
                                        <a href='https://github.com/Oswaldo-Valadez' target='_blank' rel='noreferrer' className='font-semibold text-primary'>
                                            oswaldo-valadez
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className='pt-1'>
                                <IconButton type='button' icon={() => <i className='fa-solid fa-download' />} onClick={openCV} component={SlideButton}>
                                    {t('downloadCV')}
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap lg:w-1/2 max-lg:mt-12'>
                        <div className='w-1/2 px-3.75'>
                            <InfoCard number={3} firstTextPart={t('yearsOf')} secondTextPart={t('experience')} />
                        </div>
                        <div className='w-1/2 px-3.75'>
                            <InfoCard number={12} firstTextPart={t('completedProjects.part1')} secondTextPart={t('completedProjects.part2')} />
                        </div>
                    </div>
                </div>

                <Separator />
                <h3 className='uppercase dark:text-white text-center text-common-title font-semibold leading-1-1/5 pb-12 font-poppins'>{t('mySkills')}</h3>
                <div className='flex flex-row flex-wrap'>
                    <div className='w-1/2 lg:w-1/4'>
                        <Knob percetange={100} label='html' />
                    </div>
                    <div className='w-1/2 lg:w-1/4'>
                        <Knob percetange={100} label='css' />
                    </div>
                    <div className='w-1/2 lg:w-1/4'>
                        <Knob percetange={100} label='javascript' />
                    </div>
                    <div className='w-1/2 lg:w-1/4'>
                        <Knob percetange={75} label='php' />
                    </div>
                    <div className='w-1/2 lg:w-1/4'>
                        <Knob percetange={100} label='git' />
                    </div>
                    <div className='w-1/2 lg:w-1/4'>
                        <Knob percetange={100} label='mysql' />
                    </div>
                    <div className='w-1/2 lg:w-1/4'>
                        <Knob percetange={100} label='react js' />
                    </div>
                    <div className='w-1/2 lg:w-1/4'>
                        <Knob percetange={50} label='laravel' />
                    </div>
                </div>

                <Separator />
                <h3 className='uppercase dark:text-white text-center text-common-title font-semibold leading-1-1/5 pb-12 font-poppins'>
                    {t('experienceAndEducation')}
                </h3>
                <div className='flex max-lg:flex-col'>
                    <div className='lg:w-1/2 px-3.75'>
                        <ul>
                            <li>
                                <TimelineCard
                                    icon={() => <i className='fa-solid fa-briefcase' />}
                                    periodStart={`${t('may')}, 2022`}
                                    periodEnd={t('present')}
                                    position={t('fullstackDeveloper')}
                                    place='buscabot'
                                    resume={
                                        isEnglish ? (
                                            <ul className='list-disc'>
                                                <li>
                                                    Mainly in charge of front-end development. Contributing to the creation of dynamic and responsive user
                                                    interfaces.
                                                </li>
                                                <li>Coordinating the front-end team. Use of Trello to streamline workflows.</li>
                                                <li>Implementation of modern web development practices.</li>
                                                <li>QA. Unit Tests for laravel, and e2e testing using Cypress for frontend.</li>
                                            </ul>
                                        ) : (
                                            <ul className='list-disc'>
                                                <li>
                                                    Principalmente a cargo del desarrollo front-end. Contribuyendo a la creación de interfaces de usuario
                                                    dinámicas y responsivas.
                                                </li>
                                                <li>Coordinación del equipo front-end. Uso de Trello para optimizar los flujos de trabajo.</li>
                                                <li>Implementación de prácticas modernas de desarrollo web.</li>
                                                <li>Control de calidad. Pruebas unitarias para Laravel y pruebas e2e usando Cypress para el front-end.</li>
                                            </ul>
                                        )
                                    }
                                />
                            </li>
                            <li>
                                <TimelineCard
                                    icon={() => <i className='fa-solid fa-briefcase' />}
                                    periodStart={`${t('january')}, 2021`}
                                    periodEnd={`${t('april')}, 2022`}
                                    position={t('programmerAnalyst')}
                                    place='QMC'
                                    resume={
                                        isEnglish ? (
                                            <ul className='list-disc'>
                                                <li>Build and deploy software under standard 9001-2015.</li>
                                                <li>Software maintenance.</li>
                                                <li>Provide QA to the company software.</li>
                                            </ul>
                                        ) : (
                                            <ul className='list-disc'>
                                                <li>Construir e implementar software bajo el estándar 9001-2015.</li>
                                                <li>Mantenimiento de software.</li>
                                                <li>Control de calidad.</li>
                                            </ul>
                                        )
                                    }
                                />
                            </li>
                        </ul>
                    </div>
                    <div className='lg:w-1/2 px-3.75'>
                        <ul>
                            <li>
                                <TimelineCard
                                    icon={() => <i className='fa-solid fa-graduation-cap' />}
                                    periodStart='2018'
                                    periodEnd='2022'
                                    position={t('career')}
                                    place={t('almaMater')}
                                    resume={
                                        isEnglish ? (
                                            <p>
                                                I learned to design, develop and implement computer systems in organizations, applying information and
                                                communications technologies to ensure efficiency in production processes.
                                            </p>
                                        ) : (
                                            <p>
                                                Aprendí a diseñar, desarrollar e implementar sistemas informáticos en las organizaciones, aplicando las
                                                tecnologías de información y de las comunicaciones para asegurar la eficiencia en los procesos productivos.
                                            </p>
                                        )
                                    }
                                />
                            </li>
                            <li>
                                <TimelineCard
                                    icon={() => <i className='fa-solid fa-graduation-cap' />}
                                    periodStart='2015'
                                    periodEnd='2018'
                                    position={t('programmingTechnician')}
                                    place='CETIS 80'
                                    resume={
                                        isEnglish ? (
                                            <p>
                                                I learned to use cutting-edge tools and technology that contribute to the digital transformation of the
                                                productive, business and services sphere, developing and designing creative software solutions that add value to
                                                various organizations and institutions.
                                            </p>
                                        ) : (
                                            <p>
                                                Aprendí a hacer uso de herramientas y tecnología de punta que contribuyan a la transformación digital del ámbito
                                                productivo, empresarial y de servicios, desarrollando y diseñando soluciones creativas de software que aporten
                                                valor a diversas organizaciones e instituciones.
                                            </p>
                                        )
                                    }
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
