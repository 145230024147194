import clsx from 'clsx'
import CircleButton from './CircleButton'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Nav = () => {
    const { t } = useTranslation()

    const activeClassName = (data, className) =>
        clsx(className, {
            'bg-primary lg:bg-primary dark:bg-primary': data.isActive,
            'text-white': data.isActive,
        })

    return (
        <nav className='fixed flex items-center bottom-0 left-0 w-screen bg-white dark:bg-dark-gray-primary dark:lg:bg-transparent lg:bg-transparent lg:w-auto max-lg:shadow-nav-bar dark:shadow-none lg:bottom-auto lg:left-auto lg:top-1/2 lg:right-7.5 lg:transform lg:-translate-y-1/2 z-50'>
            <ul className='flex justify-evenly w-full gap-y-5 p-2 lg:flex-col lg:p-0'>
                <li>
                    <CircleButton as={NavLink} icon={() => <i className='fa-solid fa-house' />} className={activeClassName} to='/'>
                        {t('home')}
                    </CircleButton>
                </li>
                <li>
                    <CircleButton as={NavLink} icon={() => <i className='fa-solid fa-user' />} className={activeClassName} to='/about'>
                        {t('about')}
                    </CircleButton>
                </li>
                <li>
                    <CircleButton as={NavLink} icon={() => <i className='fa-solid fa-envelope' />} className={activeClassName} to='/contact'>
                        {t('contact')}
                    </CircleButton>
                </li>
            </ul>
        </nav>
    )
}

export default Nav
