const SocialMediaLink = ({ children, ...rest }) => {
    return (
        <a
            target='_blank'
            rel='noreferrer'
            className='rounded-full dark:text-white inline-block text-[17px] h-10 w-10 leading-[42px] text-center mx-1.5 bg-bright-gray dark:bg-dark-gray-primary dark:hover:bg-primary hover:bg-primary text-granite-gray hover:text-white'
            {...rest}
        >
            {children}
        </a>
    )
}

export default SocialMediaLink
