import SectionTitle from '../components/SectionTitle'
import SocialMediaLink from '../components/SocialMediaLink'
import ContactInfo from '../components/ContactInfo'
import { useTranslation } from 'react-i18next'
// import ContactForm from '../components/Contact/ContactForm'

const Contact = () => {
    const { t } = useTranslation()

    return (
        <div className='lg:pb-17.5'>
            <div>
                <SectionTitle backTitle={t('contact')} firstTitlePart={t('getIn')} secondTitlePart={t('touch')} />
            </div>
            <div className='max-w-container mx-auto'>
                <div className='flex max-lg:flex-col'>
                    <div className='lg:w-[400px] lg:min-w-[400px] px-3.75'>
                        <div className='dark:text-white'>
                            <h3 className='uppercase text-common-title font-semibold leading-1-1/5 pb-5.5 font-poppins max-lg:text-center'>{t('dontBeShy')}</h3>
                            <p className='mb-6'>{t('contactDescription')}</p>

                            <ContactInfo href='#' icon={() => <i className='fa-solid fa-envelope' />} title={t('mailMe')} value='odvaladez@outlook.com' />

                            <ContactInfo href='#' icon={() => <i className='fa-solid fa-phone' />} title={t('callMe')} value='+52 (449) 550 73 26' />

                            <ul className='pt-3.75 pl-0 -ml-[5px] mb-12 lg:mb-5 list-none'>
                                <li className='inline-block'>
                                    <SocialMediaLink href='https://www.linkedin.com/in/oswaldo-valadez'>
                                        <i className='fa-brands fa-linkedin-in' />
                                    </SocialMediaLink>
                                </li>
                                <li className='inline-block'>
                                    <SocialMediaLink href='https://github.com/Oswaldo-Valadez'>
                                        <i className='fa-brands fa-github' />
                                    </SocialMediaLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='flex flex-1 lg:px-3.75'>{/* <ContactForm /> */}</div>
                </div>
            </div>
        </div>
    )
}

export default Contact
