import { twMerge } from 'tailwind-merge'

const Picture = ({ className }) => {
    return (
        <div
            className={twMerge(
                'lg:fixed top-10 left-10 h-[230px] w-[230px] lg:h-home-pic lg:w-1/3 rounded-full lg:rounded-3xl bg-white shadow-home-pic shadow-black bg-no-repeat bg-center bg-cover max-lg:mx-auto max-lg:border-4 border-bright-gray dark:border-dark-gray-primary',
                className,
            )}
            style={{ backgroundImage: 'url("/imgs/my-pic.webp")' }}
        />
    )
}

const WidePicture = () => {
    return (
        <>
            <div className='fixed hidden -left-wide-picture-block transform -rotate-15 -top-1/2 h-2-full w-full bg-primary lg:block'></div>
            <Picture />
        </>
    )
}

export default Picture
export { WidePicture }
