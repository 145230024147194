import { TimelineCardPlace, TimelineCardResume } from './StyledComponents'

const TimelineCard = ({ icon: Icon, periodStart, periodEnd, position, place, resume }) => {
    return (
        <div className='relative dark:text-white pl-15 pr-6 mb-12.5'>
            <div className='absolute bg-primary rounded-full h-10 left-0 text-white leading-10 right-0 text-center w-10 z-10'>
                <Icon />
            </div>
            <span className='bg-bright-gray dark:bg-dark-gray-primary rounded-full inline-block text-xs font-semibold opacity-80 leading-1-3/5 py-1px px-2.5 mb-3 font-open-sans uppercase'>
                {periodStart} - {periodEnd}
            </span>
            <h5 className='font-poppins font-medium text-lg leading-1-1/5 uppercase mt-1.75 mb-2.5'>
                {position}
                <TimelineCardPlace className='relative text-common font-semibold opacity-80 font-open-sans pl-6.5'>{place}</TimelineCardPlace>
            </h5>
            {!!resume && <TimelineCardResume className='font-open-sans font-medium text-sm leading-1-3/5'>{resume}</TimelineCardResume>}
        </div>
    )
}

export default TimelineCard
