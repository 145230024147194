import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import logger from 'redux-logger'

import auth from './reducers/auth.reducer'

const reducer = combineReducers({
    auth,
})

export const store = configureStore({
    reducer,
    middleware: getDefaultMiddelware => getDefaultMiddelware().concat(thunk).concat(logger),
})
