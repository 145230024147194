const SectionTitle = ({ backTitle, firstTitlePart, secondTitlePart }) => {
    return (
        <div className='relative flex justify-center items-center w-full uppercase font-extrabold py-20 font-poppins touch-none select-none'>
            <h1 className='dark:text-white text-[35px] lg:text-title-main leading-1-1/5'>
                {firstTitlePart}&nbsp;
                <span className='text-primary'>{secondTitlePart}</span>
            </h1>
            <span className='absolute top-1/2 left-1/2 transform -translate-x-1/2 lg:-translate-y-1/2 text-[45px] lg:text-title-back leading-7/10 spacing tracking-title-back text-back-title dark:text-back-title-dark'>
                {backTitle}
            </span>
        </div>
    )
}

export default SectionTitle
