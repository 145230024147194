import clsx from 'clsx'

const IconButton = ({ children, className, icon: Icon, component: Component = 'button', ...rest }) => {
    return (
        <Component
            {...rest}
            className={clsx(
                'group relative h-13.75 bg-transparent border border-primary rounded-full dark:text-white inline-block outline-none overflow-hidden pl-8.75 pr-17.5',
                {
                    [className]: className,
                },
            )}
        >
            <span className='font-semibold font-poppins text-common group-hover:text-white'>{children}</span>
            <div className='absolute w-13.75 h-13.75 bg-primary flex justify-center items-center text-white text-[19px] rounded-full -bottom-1px right-0'>
                {Icon && <Icon />}
            </div>
        </Component>
    )
}

export default IconButton
