import { InfoCardNumber, InfoCardContent } from './StyledComponents'

const InfoCard = ({ number, firstTextPart, secondTextPart }) => {
    return (
        <div className='border border-gainsboro dark:border-dark-gray-primary rounded-info-card pt-5 pr-7.5 pb-6.25 pl-10 mb-7.5'>
            <InfoCardNumber className='relative inline-block font-bold font-poppins text-primary text-card-info-number leading-1-1/5'>{number}</InfoCardNumber>
            <InfoCardContent className='relative lg:pl-11.25 m-0 font-open-sans uppercase dark:text-white text-common font-medium leading-1-3/5'>
                {firstTextPart}
                <span className='block'>{secondTextPart}</span>
            </InfoCardContent>
        </div>
    )
}

export default InfoCard
