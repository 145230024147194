import { Outlet, useLocation } from 'react-router-dom'
import Nav from './Nav'
import { useEffect, useState } from 'react'
import CircleButton from './CircleButton'
import { useTranslation } from 'react-i18next'

const Layout = () => {
    const location = useLocation()

    const [theme, setTheme] = useState(() => {
        if (!('theme' in localStorage)) {
            return 'system'
        }
        if (localStorage.theme === 'dark') {
            return 'dark'
        }
        return 'light'
    })

    const { i18n } = useTranslation()

    useEffect(() => {
        if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
            document.documentElement.classList.add('dark')
            return
        }
        document.documentElement.classList.remove('dark')
    }, [theme])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])

    return (
        <main className='overflow-hidden pb-15 lg:pb-0 min-h-screen'>
            <div className='show-up-animation' key={location.pathname + 'container'}>
                <Outlet />
            </div>
            <Nav />
            <div className='hide-away-animation' key={location.pathname} />
            <div className='fixed flex gap-x-1 right-[15px] lg:right-[30px] top-[15px] bg-bright-gray dark:bg-dark-gray-primary rounded-full'>
                <CircleButton
                    onClick={() => i18n.changeLanguage(i18n.resolvedLanguage === 'en' ? 'es' : 'en')}
                    icon={() => (i18n.resolvedLanguage === 'en' ? <img src='/imgs/us.svg' className='w-4' /> : <img src='/imgs/mx.svg' className='w-4' />)}
                    className='w-10 h-10 lg:w-10 lg:h-10'
                />
                <CircleButton
                    onClick={() => {
                        setTheme(prevTheme => {
                            if (prevTheme === 'light') {
                                localStorage.theme = 'dark'
                                return 'dark'
                            }

                            if (prevTheme === 'dark') {
                                localStorage.removeItem('theme')
                                return 'system'
                            }

                            localStorage.theme = 'light'
                            return 'light'
                        })
                    }}
                    icon={() =>
                        theme === 'system' ? (
                            <i className='fa-solid fa-desktop' />
                        ) : theme === 'dark' ? (
                            <i className='fa-solid fa-moon' />
                        ) : (
                            <i className='fa-solid fa-sun' />
                        )
                    }
                    className='w-10 h-10 lg:w-10 lg:h-10'
                />
            </div>
        </main>
    )
}

export default Layout
