import { twMerge } from 'tailwind-merge'

const CircleButton = ({ as: Wrapper = 'button', children, className: initialClassName, icon: Icon, type, ...rest }) => {
    const classNameIsFunction = typeof initialClassName === 'function'

    const className = twMerge(
        'group relative flex items-center justify-center w-11.25 h-11.25 lg:w-12.5 lg:h-12.5 rounded-full lg:bg-bright-gray dark:bg-dark-gray-primary dark:hover:bg-primary hover:bg-primary text-granite-gray hover:text-white dark:text-white transition-[background] duration-300',
        initialClassName,
    )

    return (
        <Wrapper {...rest} className={classNameIsFunction ? data => initialClassName(data, className) : className}>
            {Icon && <Icon />}
            {!!children && (
                <h2 className='absolute hidden top-0 right-0 opacity-0 leading-12.5 font-medium transition-all duration-300 rounded-l-full -z-10 uppercase h-12.5 m-0 px-7 lg:block group-hover:right-7 group-hover:opacity-100 group-hover:bg-primary whitespace-nowrap'>
                    {children}
                </h2>
            )}
        </Wrapper>
    )
}

export default CircleButton
