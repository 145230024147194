export default {
    translation: {
        name: 'I’m Oswaldo Valadez.',
        title: 'software engineer',
        moreAboutMe: 'MORE ABOUT ME',
        description: `
            I'm an experienced software engineer specializing in front-end web development.
            I'm passionate about creating modern solutions that are both functional and visually appealing.
            I'm highly dedicated to modern web development practices and continuous learning.
        `,
        home: 'Home',
        about: 'About',
        contact: 'Contact',
        resume: 'resume',
        me: 'me',
        personalInfos: 'personal infos',
        firstName: 'first name',
        lastName: 'last name',
        age: 'age',
        years: 'years',
        nationality: 'nationality',
        myNationality: 'Mexican',
        address: 'address',
        myAddress: 'México',
        languages: 'languages',
        myLanguages: 'Spanish, English',
        downloadCV: 'DOWNLOAD CV',
        yearsOf: 'years of',
        experience: 'experience',
        completedProjects: {
            part1: 'completed',
            part2: 'projects',
        },
        mySkills: 'my skills',
        experienceAndEducation: 'experience & education',
        getIn: 'get in',
        touch: 'touch',
        dontBeShy: 'don’t be shy !',
        mailMe: 'mail me',
        callMe: 'call me',
        contactDescription:
            'Feel free to get in touch with me. I am always open to discussing new projects, creative ideas or opportunities to be part of your projects.',
        yourName: 'YOUR NAME',
        yourEmail: 'YOUR EMAIL',
        subject: 'SUBJECT',
        yourMessage: 'YOUR MESSAGE',
        sendMessage: 'SEND MESSAGE',
        present: 'present',
        january: 'January',
        april: 'April',
        may: 'May',
        almaMater: 'Polytechnic University of Aguascalientes',
        fullstackDeveloper: 'full-stack developer',
        programmerAnalyst: 'programmer analyst',
        programmingTechnician: 'programming technician',
        career: 'Engineering in Strategic Information Systems',
    },
}
