import { KnobCircle } from '../components/StyledComponents'

const Knob = ({ percetange = 0, label }) => {
    return (
        <div className='mb-12 px-3.75'>
            <KnobCircle percetange={percetange} isHalfFull={percetange > 50} className={`p${percetange}`}>
                <span className='absolute block font-poppins font-medium dark:text-white left-0 text-center top-0 hover:cursor-default'>{percetange}%</span>
                <div className='slice'>
                    <div className='bar'></div>
                    <div className='fill'></div>
                </div>
            </KnobCircle>
            <h6 className='uppercase mb-2 mt-6 dark:text-white text-center font-medium font-open-sans'>{label}</h6>
        </div>
    )
}

export default Knob
