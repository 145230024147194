export default {
    translation: {
        name: 'Soy Oswaldo Valadez.',
        title: 'ingeniero de software',
        moreAboutMe: 'MÁS ACERCA DE MÍ',
        description: `
            Soy un ingeniero de software experimentado especializado en el desarrollo web de front-end.
            Me apasiona crear soluciones modernas que son tanto funcionales como visualmente atractivas.
            Tengo gran dedicación a las prácticas modernas de desarrollo web y al aprendizaje continuo.
        `,
        home: 'Inicio',
        about: 'Acerca de',
        contact: 'Contacto',
        resume: 'resumen',
        me: 'mí',
        personalInfos: 'información personal',
        firstName: 'nombre',
        lastName: 'apellido',
        age: 'edad',
        years: 'años',
        nationality: 'nacionalidad',
        myNationality: 'Mexicano',
        address: 'dirección',
        myAddress: 'México',
        languages: 'idiomas',
        myLanguages: 'Español, Inglés',
        downloadCV: 'DESCARGAR CV',
        yearsOf: 'años de',
        experience: 'experiencia',
        completedProjects: {
            part1: 'proyectos',
            part2: 'completados',
        },
        mySkills: 'mis habilidades',
        experienceAndEducation: 'experiencia y educación',
        getIn: 'contacta',
        touch: 'conmigo',
        dontBeShy: '¡ no seas tímido !',
        mailMe: 'escríbeme',
        callMe: 'llámame',
        contactDescription:
            'No dudes en ponerte en contacto conmigo. Siempre estoy abierto a discutir nuevos proyectos, ideas creativas u oportunidades de ser parte de tus proyectos.',
        yourName: 'TU NOMBRE',
        yourEmail: 'TU EMAIL',
        subject: 'ASUNTO',
        yourMessage: 'TU MENSAJE',
        sendMessage: 'ENVIAR MENSAJE',
        present: 'presente',
        january: 'Enero',
        april: 'Abril',
        may: 'Mayo',
        almaMater: 'universidad politécnica de aguascalientes',
        fullstackDeveloper: 'desarrollador full-stack',
        programmerAnalyst: 'analísta programador',
        programmingTechnician: 'técnico en programación',
        career: 'INGENIERÍA EN SISTEMAS ESTRATÉGICOS DE LA INFORMACIÓN',
    },
}
