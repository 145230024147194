const ContactInfo = ({ title, value, href, icon: Icon }) => {
    return (
        <p className='relative font-semibold leading-[25px] mb-5 pl-12.5 pt-[5px]'>
            <span className='absolute left-0 text-[33px] top-[10px] text-primary'>
                <Icon />
            </span>
            <span className='block font-normal uppercase opacity-80'>{title}</span> <a href={href}>{value}</a>
        </p>
    )
}

export default ContactInfo
