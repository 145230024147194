import { WidePicture } from '../components/Picture'
import IconButton from '../components/IconButton'
import { SlideButton, HomeTitle } from '../components/StyledComponents'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Home = () => {
    const { t } = useTranslation()

    return (
        <div className='flex max-lg:flex-col max-lg:py-12.5'>
            <WidePicture />
            <div className='flex justify-center items-center max-lg:px-4 lg:ml-auto lg:pl-4 lg:pr-7.5 lg:w-2/3 lg:h-screen transform max-lg:text-center'>
                <div className='mx-auto max-w-150'>
                    <HomeTitle className='relative text-primary font-poppins font-bold uppercase text-2xl lg:text-5xl mt-6.25 lg:mt-4.5 mb-2.5 lg:pl-17.5 leading-main-title-small lg:leading-main-title-large whitespace-nowrap'>
                        {t('name')}
                        <br />
                        <span className='text-black dark:text-white'>{t('title')}</span>
                    </HomeTitle>
                    <p className='font-open-sans dark:text-white text-sm lg:text-base leading-7 lg:leading-home-resume mt-3.75 mb-7'>{t('description')}</p>
                    <NavLink to='/about'>
                        <IconButton icon={() => <i className='fa-solid fa-arrow-right' />} component={SlideButton}>
                            {t('moreAboutMe')}
                        </IconButton>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default Home
